.submitbutton {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1.5rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 500ms;
  color: #ffffff;
  width: 100%;
  border-radius: 0.5rem;
  border: none;
  background-color: #f6d56f;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Gotham A", "Gotham B";
  cursor: pointer;
}

.errormessage {
  padding: 0.5rem;
  margin-top: 1.25rem;
  background-color: #fef2f2;
  color: #991b1b;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: #fecaca;
}

.successmessage {
  padding: 0.5rem;
  margin-top: 1.25rem;
  background-color: #ecfdf5;
  color: #059669;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: #a7f3d0;
}

@media screen and (min-width: 1040px) {
  .container {
    padding: 0px 20px;
  }
}
