.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  background-color: white;
  color: white;
  padding: 0 0px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
  z-index: 10;
}

.header {
  max-width: 1280px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}

.logo {
  width: 220px;
  height: auto;
}

.logo img {
  width: 100%;
  object-fit: contain;
}

/* MOBILE NAVIGATION */

.mobilenavbar {
  display: block;
  color: #1c3565;
}

/* NAVIGATION STYLING BELOW */

/* Navbar container */
.navbar {
  overflow: hidden;
  height: 6rem;
  display: none;
  align-items: center;
}

/* Links inside the navbar */
.navbar a {
  float: left;
  font-size: 14px;
  font-weight: 500;
  color: #a6a8aa;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 14px;
  border: none;
  outline: none;
  color: #a6a8aa;
  font-weight: 500;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover,
.dropdown:hover .dropbtn {
  color: black;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: #a6a8aa;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #0093c6;
  color: white;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (min-width: 481px) {
}

@media screen and (min-width: 880px) {
  .navbar {
    display: flex;
  }

  .mobilenavbar {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
}

@media screen and (min-width: 1336px) {
  .header {
    padding: 0px 20px;
  }
}

@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 2400px) {
}
