.section {
  /* background-image: url("../../assets/hero/KoreanBanner.png"); */
  height: auto;
  width: 100%;
  max-width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  margin-top: 3rem;
}

.section h1 {
  text-transform: uppercase;
  color: #8cbe4f;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Gotham A", "Gotham B";
}

.section h2 {
  text-transform: lowercase;
  color: #db812e;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: mojito, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.herotext {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  padding-left: 20px;
  transform: translate(-50%, -50%);
  max-width: 1280px;
}

.herotext img {
  width: 600px;
  height: auto;
}

.section img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (min-width: 480px) {
  .section h1 {
    font-size: 2.8rem;
    /* letter-spacing: -5px; */
  }
}

@media screen and (min-width: 628px) {
  .section h1 {
    font-size: 3.4rem;
  }
}

@media screen and (min-width: 768px) {
  .section h1 {
    font-size: 3.8rem;
  }

  .herotext img {
    width: 400px;
    height: auto;
  }
}

@media screen and (min-width: 882px) {
  .section h1 {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1040px) {
  .section h1 {
    font-size: 6rem;
    letter-spacing: -3px;
  }
}

@media screen and (min-width: 1300px) {
  .section h1 {
    font-size: 7.8rem;
  }

  .herotext img {
    width: 600px;
    height: auto;
  }
}

@media screen and (min-width: 1600px) {
  .section h1 {
    font-size: 8.6rem;
  }

  .section {
    margin-left: auto;
    margin-right: auto;
    width: 1920px;
  }
}

@media screen and (min-width: 1920px) {
  .section h1 {
    font-size: 9.6rem;
  }
}
