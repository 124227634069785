.mobilemenu {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.mobilemenu a {
  padding: 16px 0px;
  color: #1c3565;
  text-decoration: none;
  display: block;
  text-align: left;
}
