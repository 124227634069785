.selectcontainer {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 500ms;
  text-transform: capitalize;
  width: 100%;
  border-bottom-width: 2px;
  border-bottom-color: #c8d856;
}
