.gridcontainer {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  /* grid-template-columns: repeat(1, minmax(0, 1fr)); */
  width: 100%;
  height: 5px;
}

.item1 {
  background-color: #0093c6;
  /* background-image: linear-gradient(
    to right,
    #0093c6,
    #0093c6,
    #0093c6,
    #8cbe4f,
    #8cbe4f,
    #8cbe4f,
    #db812e,
    #db812e,
    #db812e,
    #cf4978,
    #cf4978,
    #cf4978,
    #1c3565,
    #1c3565,
    #1c3565
  ); */
}

.item2 {
  background-color: #8cbe4f;
}

.item3 {
  background-color: #db812e;
}

.item4 {
  background-color: #cf4978;
}

.item5 {
  background-color: #1c3565;
}
