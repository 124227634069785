.contactcontainer {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 12px;
  color: gray;
}

.contactcontainer i {
  margin-right: 10px;
}

.contactcontainer h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 400;
}

.selectmenu {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

select {
  border: none;
  border-bottom: 2px #0093c6 solid;
  padding: 5px;
  width: 100%;
  font-family: "Gotham A", "Gotham B";
}

label {
  font-size: 10px;
  color: gray;
}

option {
  padding: 0px 5px;
}

@media screen and (min-width: 1025px) {
  select {
    width: 200px;
  }
}
