.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

.button {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1000;
  border: none;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c3565;
}
