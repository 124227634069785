@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html {
  font-family: "Gotham A", "Gotham B";
}

body {
  margin: 0;
  background-color: #fff;
}

.HeroSection_section___O62n {
  /* background-image: url("../../assets/hero/KoreanBanner.png"); */
  height: auto;
  width: 100%;
  max-width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  margin-top: 3rem;
}

.HeroSection_section___O62n h1 {
  text-transform: uppercase;
  color: #8cbe4f;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Gotham A", "Gotham B";
}

.HeroSection_section___O62n h2 {
  text-transform: lowercase;
  color: #db812e;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: mojito, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.HeroSection_herotext__1bp75 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  padding-left: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 1280px;
}

.HeroSection_herotext__1bp75 img {
  width: 600px;
  height: auto;
}

.HeroSection_section___O62n img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (min-width: 480px) {
  .HeroSection_section___O62n h1 {
    font-size: 2.8rem;
    /* letter-spacing: -5px; */
  }
}

@media screen and (min-width: 628px) {
  .HeroSection_section___O62n h1 {
    font-size: 3.4rem;
  }
}

@media screen and (min-width: 768px) {
  .HeroSection_section___O62n h1 {
    font-size: 3.8rem;
  }

  .HeroSection_herotext__1bp75 img {
    width: 400px;
    height: auto;
  }
}

@media screen and (min-width: 882px) {
  .HeroSection_section___O62n h1 {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1040px) {
  .HeroSection_section___O62n h1 {
    font-size: 6rem;
    letter-spacing: -3px;
  }
}

@media screen and (min-width: 1300px) {
  .HeroSection_section___O62n h1 {
    font-size: 7.8rem;
  }

  .HeroSection_herotext__1bp75 img {
    width: 600px;
    height: auto;
  }
}

@media screen and (min-width: 1600px) {
  .HeroSection_section___O62n h1 {
    font-size: 8.6rem;
  }

  .HeroSection_section___O62n {
    margin-left: auto;
    margin-right: auto;
    width: 1920px;
  }
}

@media screen and (min-width: 1920px) {
  .HeroSection_section___O62n h1 {
    font-size: 9.6rem;
  }
}

.FirstVideo_section__26SEA {
  height: auto;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.FirstVideo_wrapper__3dgYS {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 0px 0px;
}

.FirstVideo_fibremax__9Mw4c {
  width: 100%;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  order: 2;
}

.FirstVideo_flexvideocontainer__2FTJo {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  order: 1;
}

.FirstVideo_section__26SEA h1 {
  text-align: center;
  text-transform: uppercase;
  padding: 0px 10px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.FirstVideo_apply__aJ3cp {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  margin-top: 0px;
}

.FirstVideo_apply__aJ3cp button {
  width: 250px;
  cursor: pointer;
  margin-top: 0px;
  padding-top: 0px;
  background-color: #023166;
  /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */
  border: none;
}

p {
  padding-left: 10px;
  padding-right: 10px;
}

.FirstVideo_subheader__2xqm9 {
  color: #ffffff;
  /* text-shadow: 1px 1px 1px #a7a7a7, 1px 1px 1px #a7a7a7; */
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}

@media screen and (min-width: 1025px) {
  .FirstVideo_section__26SEA {
    height: auto;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .FirstVideo_fibremax__9Mw4c {
    width: 45%;
    text-align: left;
    padding-left: 0px;
    padding-right: 40px;
    margin-top: 0px;
    order: 1;
  }

  .FirstVideo_flexvideocontainer__2FTJo {
    margin-top: 0px;
    width: 55%;
    order: 2;
  }

  .FirstVideo_wrapper__3dgYS {
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0px;
  }
}

@media screen and (min-width: 1200px) {
  .FirstVideo_section__26SEA h1 {
    font-size: 3.4rem;
    line-height: 1;
  }
}

@media screen and (min-width: 1336px) {
  .FirstVideo_section__26SEA {
    padding-left: 0px;
    padding-right: 0px;
  }

  .FirstVideo_wrapper__3dgYS {
    max-width: 1240px;
  }
}

.FirstVideo_embed-border__3Qa_u {
  box-sizing: border-box; /* prevents extra width from being added */
  outline: none;
}

/* aspect ratio styles */
.FirstVideo_embed-responsive__2Wvls {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}
.FirstVideo_embed-responsive__2Wvls iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.FirstVideo_embed-responsive__2Wvls iframe:focus {
  outline: none;
}

.FirstVideo_wrapper__1jfQX {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

@media only screen and (min-width: 1025px) {
  .FirstVideo_embed-border__3Qa_u {
    border: 15px solid #f6d56f;
    background-color: #f6d56f;
  }
  .FirstVideo_wrapper__1jfQX {
    max-width: 700px;
    background-color: #f6d56f;
  }
}

.SecondVideo_section__18sBw {
  height: auto;
  background-color: #c8d856;
  padding-top: 20px;
  padding-bottom: 20px;
}

.SecondVideo_wrapper__vZ8jC {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 0px 0px;
  position: relative;
}

.SecondVideo_fibremax__1tXlv {
  width: 100%;
  text-align: center;
  padding: 20px;
  padding-bottom: 30px;
  margin-top: 10px;
  order: 2;
}

.SecondVideo_flexvideocontainer__2RRgW {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  order: 1;
}

.SecondVideo_section__18sBw h1 {
  text-align: center;
  text-transform: uppercase;
  padding: 0px 10px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.SecondVideo_apply__1vbWU {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  margin-top: 0px;
}

.SecondVideo_apply__1vbWU button {
  width: 250px;
  cursor: pointer;
  margin-top: 0px;
  padding-top: 0px;
  background-color: #023166;
  /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */
  border: none;
}

p {
  padding-left: 10px;
  padding-right: 10px;
}

.SecondVideo_subheader__2ixdV {
  color: #ffffff;
  /* text-shadow: 1px 1px 1px #a7a7a7, 1px 1px 1px #a7a7a7; */
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}

.SecondVideo_prev__uT5tA {
  display: none;
}

.SecondVideo_next__1i93z {
  display: none;
}

@media screen and (min-width: 768px) {
  .SecondVideo_prev__uT5tA {
    position: absolute;
    display: block;
    top: 50%;
    left: 16px;
    z-index: 10;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    box-shadow: 0 0 20px #ccc;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f9e196;
  }

  .SecondVideo_next__1i93z {
    position: absolute;
    overflow: visible;
    display: block;
    top: 50%;
    right: 16px;
    z-index: 10;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    box-shadow: 0 0 20px #ccc;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f9e196;
  }
}

@media screen and (min-width: 1025px) {
  .SecondVideo_section__18sBw {
    height: auto;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .SecondVideo_fibremax__1tXlv {
    width: 45%;
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 0px;
    order: 2;
    color: white;
  }

  .SecondVideo_flexvideocontainer__2RRgW {
    margin-top: 0px;
    width: 55%;
    order: 1;
  }

  .SecondVideo_wrapper__vZ8jC {
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0px;
  }
}

@media screen and (min-width: 1200px) {
  .SecondVideo_section__18sBw h1 {
    font-size: 3.4rem;
    line-height: 1;
  }
}

@media screen and (min-width: 1336px) {
  .SecondVideo_section__18sBw {
    padding-left: 0px;
    padding-right: 0px;
  }

  .SecondVideo_wrapper__vZ8jC {
    max-width: 1240px;
  }

  .SecondVideo_prev__uT5tA {
    left: -30px;
  }

  .SecondVideo_next__1i93z {
    right: -20px;
  }
}

.ColourGrid_gridcontainer__IbX_p {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  /* grid-template-columns: repeat(1, minmax(0, 1fr)); */
  width: 100%;
  height: 5px;
}

.ColourGrid_item1__2hU8k {
  background-color: #0093c6;
  /* background-image: linear-gradient(
    to right,
    #0093c6,
    #0093c6,
    #0093c6,
    #8cbe4f,
    #8cbe4f,
    #8cbe4f,
    #db812e,
    #db812e,
    #db812e,
    #cf4978,
    #cf4978,
    #cf4978,
    #1c3565,
    #1c3565,
    #1c3565
  ); */
}

.ColourGrid_item2__2YxoZ {
  background-color: #8cbe4f;
}

.ColourGrid_item3__2H2AE {
  background-color: #db812e;
}

.ColourGrid_item4__2E_jj {
  background-color: #cf4978;
}

.ColourGrid_item5__2uY_F {
  background-color: #1c3565;
}

.MobileMenu_mobilemenu__1-PcQ {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.MobileMenu_mobilemenu__1-PcQ a {
  padding: 16px 0px;
  color: #1c3565;
  text-decoration: none;
  display: block;
  text-align: left;
}

.MobileModal_backdrop__2qOKn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.MobileModal_modal__3FxWj {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  -webkit-animation: MobileModal_slide-down__3XQ0Y 300ms ease-out forwards;
          animation: MobileModal_slide-down__3XQ0Y 300ms ease-out forwards;
}

.MobileModal_button__xQetT {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1000;
  border: none;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c3565;
}

.Header_container__1LnXa {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  background-color: white;
  color: white;
  padding: 0 0px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
  z-index: 10;
}

.Header_header__zSTUo {
  max-width: 1280px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}

.Header_logo__3Cuqg {
  width: 220px;
  height: auto;
}

.Header_logo__3Cuqg img {
  width: 100%;
  object-fit: contain;
}

/* MOBILE NAVIGATION */

.Header_mobilenavbar__1YIAN {
  display: block;
  color: #1c3565;
}

/* NAVIGATION STYLING BELOW */

/* Navbar container */
.Header_navbar__3czsp {
  overflow: hidden;
  height: 6rem;
  display: none;
  align-items: center;
}

/* Links inside the navbar */
.Header_navbar__3czsp a {
  float: left;
  font-size: 14px;
  font-weight: 500;
  color: #a6a8aa;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* The dropdown container */
.Header_dropdown__2X-A9 {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.Header_dropdown__2X-A9 .Header_dropbtn__1SQov {
  font-size: 14px;
  border: none;
  outline: none;
  color: #a6a8aa;
  font-weight: 500;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.Header_navbar__3czsp a:hover,
.Header_dropdown__2X-A9:hover .Header_dropbtn__1SQov {
  color: black;
}

/* Dropdown content (hidden by default) */
.Header_dropdown-content__1jxwP {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.Header_dropdown-content__1jxwP a {
  float: none;
  color: #a6a8aa;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.Header_dropdown-content__1jxwP a:hover {
  background-color: #0093c6;
  color: white;
}

/* Show the dropdown menu on hover */
.Header_dropdown__2X-A9:hover .Header_dropdown-content__1jxwP {
  display: block;
}

@media screen and (min-width: 481px) {
}

@media screen and (min-width: 880px) {
  .Header_navbar__3czsp {
    display: flex;
  }

  .Header_mobilenavbar__1YIAN {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
}

@media screen and (min-width: 1336px) {
  .Header_header__zSTUo {
    padding: 0px 20px;
  }
}

@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 2400px) {
}

.SecondSection_section__2ajke {
  height: auto;
  background-color: #ffffff;
  padding: 40px 0px;
}

.SecondSection_section__2ajke button {
  background-color: #f6d56f;
  padding: 10px 20px;
  border: none;
  margin-top: 14px;
  cursor: pointer;
}

.SecondSection_buttontext__5XliB {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
  color: white;
}

.SecondSection_heading__18Mle {
  color: #c8d856;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
}

.SecondSection_subheading__zEFdZ {
  color: gray;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.SecondSection_underline__IE3xN {
  -webkit-text-decoration: underline solid gray 2%;
          text-decoration: underline solid gray 2%;
  text-underline-offset: 2px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.SecondSection_container__V54Qj {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.SecondSection_container__V54Qj img {
  max-width: 90%;
}

@media screen and (min-width: 1025px) {
  .SecondSection_section__2ajke {
    height: auto;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 30px;
    /* margin: 0rem auto 1.1rem auto; */
  }

  .SecondSection_subheading__zEFdZ {
    padding-bottom: 0px;
  }

  .SecondSection_container__V54Qj {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1200px) {
  .SecondSection_section__2ajke {
    height: auto;
  }
}

.MainSection_section__3YcAx {
  height: auto;
  /* background-image: url("../../assets/bg-splitcolour.jpg"); */
  background-image: linear-gradient(
    #8cbe4f,
    #8cbe4f,
    #8cbe4f,
    #8cbe4f,
    #8cbe4f,
    #8cbe4f,
    #db812e,
    #db812e,
    #db812e,
    #db812e
  );

  padding: 40px 0px;
}

.MainSection_container__3ydS8 {
  display: flex;
  flex-direction: column;
}

.MainSection_firstsection__1BdsZ {
  color: white;
  padding-top: 16px;
  text-align: center;
  order: 2;
}

/* Second Section Stuff */

.MainSection_secondsection__9YQs2 {
  display: flex;
  justify-content: center;
  align-items: center;
  order: 1;

  /* width: 100%; */
}

.MainSection_secondsection__9YQs2 img {
  width: 180px;
  height: auto;
  max-width: 360px;
}

/* Third Section Stuff */
.MainSection_thirdsection__ZvYMR {
  color: white;
  padding-top: 16px;
  text-align: center;
  order: 3;
}

.MainSection_heading__2zTvF {
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}

h3 {
  font-size: 20px;
}

.MainSection_underline__3XZMg {
  -webkit-text-decoration: underline solid white 2%;
          text-decoration: underline solid white 2%;
  text-underline-offset: 2px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.MainSection_subheading__GugKs {
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

ul {
  list-style-type: none;
  width: 100%;
  white-space: normal;
  padding-left: 0px;
}

@media screen and (min-width: 768px) {
  .MainSection_secondsection__9YQs2 img {
    width: 270px;
  }
}

@media screen and (min-width: 1025px) {
  .MainSection_section__3YcAx {
    height: auto;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background-image: url(/static/media/bg-splitcolour.a2ae55be.jpg);
    background-repeat: no-repeat;

    background-position: center center;

    /* margin: 0rem auto 1.1rem auto; */
  }

  .MainSection_firstsection__1BdsZ {
    width: 35%;
    text-align: left;
    order: 1;
  }

  /* Second Section Stuff */

  .MainSection_secondsection__9YQs2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    order: 2;

    /* width: 100%; */
  }

  /* Third Section Stuff */
  .MainSection_thirdsection__ZvYMR {
    width: 35%;
    text-align: right;
    order: 3;
  }

  .MainSection_secondsection__9YQs2 img {
    width: 340px;
  }

  .MainSection_container__3ydS8 {
    max-width: 1280px;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1200px) {
  .MainSection_section__3YcAx {
    height: auto;
  }
}

.CountryContact_contactcontainer__2gRU3 {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 12px;
  color: gray;
}

.CountryContact_contactcontainer__2gRU3 i {
  margin-right: 10px;
}

.CountryContact_contactcontainer__2gRU3 h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 400;
}

.CountryContact_selectmenu__3ewL3 {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

select {
  border: none;
  border-bottom: 2px #0093c6 solid;
  padding: 5px;
  width: 100%;
  font-family: "Gotham A", "Gotham B";
}

label {
  font-size: 10px;
  color: gray;
}

option {
  padding: 0px 5px;
}

@media screen and (min-width: 1025px) {
  select {
    width: 200px;
  }
}

.FormSection_container__3ZbiP {
  width: 100%;
  background-color: #ffffff;
  height: auto;
  padding: 20px;
  margin-top: 20px;
}

.FormSection_wrapper__1j5St {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.FormSection_heading__2RTEF {
  color: #c8d856;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
}

.FormSection_subheading__2y62q {
  color: gray;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.FormSection_formheading__6l33h {
  color: gray;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

@media screen and (min-width: 1025px) {
  .FormSection_wrapper__1j5St {
    flex-direction: row;
  }

  .FormSection_form__RObly {
    width: 49%;
    text-align: right;
    padding-right: 40px;
  }

  .FormSection_seperator__1ZCvH {
    width: 1px;
    background-color: #c8d856;
    height: auto;
  }

  .FormSection_contact-detail__1TG-E {
    width: 49%;
    padding-left: 40px;
  }

  .FormSection_formheading__6l33h {
    text-align: left;
  }
}

.TextInput_inputcontainer__3NYbq {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, -webkit-transform;
  transition-duration: 500ms;
  text-transform: capitalize;
  width: 100%;
  border: none;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #c8d856;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.TextInput_inputlabel__239xY {
  width: 100%;
  text-align: left;
  color: transparent;
}

.SelectInput_selectcontainer__2BaSx {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, -webkit-transform;
  transition-duration: 500ms;
  text-transform: capitalize;
  width: 100%;
  border-bottom-width: 2px;
  border-bottom-color: #c8d856;
}

.ContactForm_submitbutton__3_ceK {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1.5rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, -webkit-transform;
  transition-duration: 500ms;
  color: #ffffff;
  width: 100%;
  border-radius: 0.5rem;
  border: none;
  background-color: #f6d56f;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Gotham A", "Gotham B";
  cursor: pointer;
}

.ContactForm_errormessage__Mu2Ea {
  padding: 0.5rem;
  margin-top: 1.25rem;
  background-color: #fef2f2;
  color: #991b1b;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: #fecaca;
}

.ContactForm_successmessage__1H-h8 {
  padding: 0.5rem;
  margin-top: 1.25rem;
  background-color: #ecfdf5;
  color: #059669;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: #a7f3d0;
}

@media screen and (min-width: 1040px) {
  .ContactForm_container__3e_dU {
    padding: 0px 20px;
  }
}

