.section {
  height: auto;
  background-color: #ffffff;
  padding: 40px 0px;
}

.section button {
  background-color: #f6d56f;
  padding: 10px 20px;
  border: none;
  margin-top: 14px;
  cursor: pointer;
}

.buttontext {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
  color: white;
}

.heading {
  color: #c8d856;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
}

.subheading {
  color: gray;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.underline {
  text-decoration: underline solid gray 2%;
  text-underline-offset: 2px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.container img {
  max-width: 90%;
}

@media screen and (min-width: 1025px) {
  .section {
    height: auto;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 30px;
    /* margin: 0rem auto 1.1rem auto; */
  }

  .subheading {
    padding-bottom: 0px;
  }

  .container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1200px) {
  .section {
    height: auto;
  }
}
