.section {
  height: auto;
  /* background-image: url("../../assets/bg-splitcolour.jpg"); */
  background-image: linear-gradient(
    #8cbe4f,
    #8cbe4f,
    #8cbe4f,
    #8cbe4f,
    #8cbe4f,
    #8cbe4f,
    #db812e,
    #db812e,
    #db812e,
    #db812e
  );

  padding: 40px 0px;
}

.container {
  display: flex;
  flex-direction: column;
}

.firstsection {
  color: white;
  padding-top: 16px;
  text-align: center;
  order: 2;
}

/* Second Section Stuff */

.secondsection {
  display: flex;
  justify-content: center;
  align-items: center;
  order: 1;

  /* width: 100%; */
}

.secondsection img {
  width: 180px;
  height: auto;
  max-width: 360px;
}

/* Third Section Stuff */
.thirdsection {
  color: white;
  padding-top: 16px;
  text-align: center;
  order: 3;
}

.heading {
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}

h3 {
  font-size: 20px;
}

.underline {
  text-decoration: underline solid white 2%;
  text-underline-offset: 2px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.subheading {
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

ul {
  list-style-type: none;
  width: 100%;
  white-space: normal;
  padding-left: 0px;
}

@media screen and (min-width: 768px) {
  .secondsection img {
    width: 270px;
  }
}

@media screen and (min-width: 1025px) {
  .section {
    height: auto;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background-image: url("../../assets/bg-splitcolour.jpg");
    background-repeat: no-repeat;

    background-position: center center;

    /* margin: 0rem auto 1.1rem auto; */
  }

  .firstsection {
    width: 35%;
    text-align: left;
    order: 1;
  }

  /* Second Section Stuff */

  .secondsection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    order: 2;

    /* width: 100%; */
  }

  /* Third Section Stuff */
  .thirdsection {
    width: 35%;
    text-align: right;
    order: 3;
  }

  .secondsection img {
    width: 340px;
  }

  .container {
    max-width: 1280px;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1200px) {
  .section {
    height: auto;
  }
}
