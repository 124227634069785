.section {
  height: auto;
  background-color: #c8d856;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 0px 0px;
  position: relative;
}

.fibremax {
  width: 100%;
  text-align: center;
  padding: 20px;
  padding-bottom: 30px;
  margin-top: 10px;
  order: 2;
}

.flexvideocontainer {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  order: 1;
}

.section h1 {
  text-align: center;
  text-transform: uppercase;
  padding: 0px 10px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.apply {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  margin-top: 0px;
}

.apply button {
  width: 250px;
  cursor: pointer;
  margin-top: 0px;
  padding-top: 0px;
  background-color: #023166;
  /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */
  border: none;
}

p {
  padding-left: 10px;
  padding-right: 10px;
}

.subheader {
  color: #ffffff;
  /* text-shadow: 1px 1px 1px #a7a7a7, 1px 1px 1px #a7a7a7; */
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}

.prev {
  display: none;
}

.next {
  display: none;
}

@media screen and (min-width: 768px) {
  .prev {
    position: absolute;
    display: block;
    top: 50%;
    left: 16px;
    z-index: 10;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    box-shadow: 0 0 20px #ccc;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f9e196;
  }

  .next {
    position: absolute;
    overflow: visible;
    display: block;
    top: 50%;
    right: 16px;
    z-index: 10;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    box-shadow: 0 0 20px #ccc;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f9e196;
  }
}

@media screen and (min-width: 1025px) {
  .section {
    height: auto;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .fibremax {
    width: 45%;
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 0px;
    order: 2;
    color: white;
  }

  .flexvideocontainer {
    margin-top: 0px;
    width: 55%;
    order: 1;
  }

  .wrapper {
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0px;
  }
}

@media screen and (min-width: 1200px) {
  .section h1 {
    font-size: 3.4rem;
    line-height: 1;
  }
}

@media screen and (min-width: 1336px) {
  .section {
    padding-left: 0px;
    padding-right: 0px;
  }

  .wrapper {
    max-width: 1240px;
  }

  .prev {
    left: -30px;
  }

  .next {
    right: -20px;
  }
}
