.embed-border {
  box-sizing: border-box; /* prevents extra width from being added */
  outline: none;
}

/* aspect ratio styles */
.embed-responsive {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}
.embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed-responsive iframe:focus {
  outline: none;
}

.wrapper {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

@media only screen and (min-width: 1025px) {
  .embed-border {
    border: 15px solid #f6d56f;
    background-color: #f6d56f;
  }
  .wrapper {
    max-width: 700px;
    background-color: #f6d56f;
  }
}
