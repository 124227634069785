.container {
  width: 100%;
  background-color: #ffffff;
  height: auto;
  padding: 20px;
  margin-top: 20px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.heading {
  color: #c8d856;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
}

.subheading {
  color: gray;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.formheading {
  color: gray;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

@media screen and (min-width: 1025px) {
  .wrapper {
    flex-direction: row;
  }

  .form {
    width: 49%;
    text-align: right;
    padding-right: 40px;
  }

  .seperator {
    width: 1px;
    background-color: #c8d856;
    height: auto;
  }

  .contact-detail {
    width: 49%;
    padding-left: 40px;
  }

  .formheading {
    text-align: left;
  }
}
